import { downloadExcel } from '@/support/Client';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import { formatDate } from '@/support/String';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { Report } from '@/models/Report';
import { RejectReason as RejectReasonModel } from '@/models/RejectReason';
import { getFirstTimeRightName, FtrConfirmationStatusMap, ftrConfirmationStatusMap } from '@/support/FirstTimeRight';
import { DamageVisibility } from '@/components/damage-panel/DamagePanel';
import { Damage } from '@/models/DamageNew';
import { reportHeadersFromKey } from '@/support/ReportHeaders';
import { User } from '@/models/User';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';

@Component<FtrLogboek>({
  components: {
    PeriodFilter,
  },
})
export default class FtrLogboek extends Vue {
  public $pageTitle = 'Logboek FTR';

  // loaders
  protected isLoading = true;

  protected isLoadingExport = false;

  protected filters: FtrLogboekFilters = {
    ftr_statuses: ['pending'],
  };

  protected tableFilters: FtrLogboekTableFilters = {};

  protected kpiftr = false;

  // organizations
  protected organizations: Organization[] = [];

  // report dialog
  protected isEditingReport = false;

  protected report: Report | null = null;

  protected damages: Damage[] | null = null;

  protected damageVisibility: DamageVisibility = {
    validations: true,
    rejectReasons: true,
    media: true,
    repairs: true,
    status: true,
  };

  // others filters
  protected ftrConfirmationStatusMap: FtrConfirmationStatusMap[] = ftrConfirmationStatusMap;

  // Datatable
  protected visibility: TableVisibility = {
    checkboxes: false,
    title: false,
    search: false,
  };

  // Dates
  protected databaseDate = '0000-00-00 00:00:00';

  protected chartData: any | null = null;

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    this.$pageTitle = this.hasTcmgQuery ? 'Logboek FTR IMG' : 'Logboek FTR Bureau';
    const lastDayOfMonth = DateTime.local().daysInMonth;
    this.filters.from = `${DateTime.local().toFormat('yyyy-MM')}-01`;
    this.filters.to = `${DateTime.local().toFormat('yyyy-MM')}-${lastDayOfMonth}`;

    this.emitBreadcrumb();
    await this.getOrganizations();

    this.$nextTick(() => {
      this.applyFilters();
    });
    this.isLoading = false;
  }

  protected async getOrganizations() {
    await new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;

        if (this.$store.state.isServiceOrganization) {
          this.filters.organization = organizations[0]?.id || '';
        } else {
          this.filters.organization = this.$store.state.Auth.organization.id;
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected applyFilters() {
    const filters: FtrLogboekTableFilters = {
      downloaded_at: [this.filters.from, this.filters.to],
      ftr: this.hasTcmgQuery ? 'tcmg' : 'bureau',
      kpiftr: this.kpiftr,
      departments: this.filters.departments,
      is_test: false,
    };

    if (! this.hasTcmgQuery && this.$store.state.isServiceOrganization) {
      filters.organization = this.filters.organization;
    }

    if (! this.hasTcmgQuery && ! this.$store.state.isServiceOrganization) {
      if (this.filters.ftr_statuses) {
        filters.ftr_statuses = this.filters.ftr_statuses;
      }
      if (this.filters.experts) {
        filters.experts = this.filters.experts;
      }
    }

    this.tableFilters = filters;
  }

  protected async downloadExport() {
    if (! this.filters.from || ! this.filters.to) {
      return;
    }

    this.isLoadingExport = true;

    const url = (! this.hasTcmgQuery) ? '/v1/stats/download/ftr-logbook-bureau' : '/v1/stats/download/ftr-logbook';

    const options = {
      url,
    };

    const payload = new URLSearchParams();
    if (this.filters.from) {
      payload.append('period[from]', this.filters.from || '');
    }
    if (this.filters.to) {
      payload.append('period[to]', this.filters.to || '');
    }

    payload.append('kpiftr', `${this.kpiftr}`);

    if (! this.hasTcmgQuery) {
      payload.append('organization', this.filters?.organization || '');
    }

    await downloadExcel(options, payload);

    this.isLoadingExport = false;
  }

  protected applyDates(from: string, to: string) {
    this.filters.from = from;
    this.filters.to = to;
  }

  protected parseRejectReasons(reject_reasons: RejectReasonModel[]) {
    if (! reject_reasons || ! reject_reasons.length) {
      return '';
    }

    let rejectReasonsString = '';
    reject_reasons.forEach((reason: RejectReasonModel) => {
      rejectReasonsString += `<span class="block">- ${reason.name}<span>`;
    });

    return rejectReasonsString;
  }

  protected updateFTR(report: Report, value: boolean) {
    report.uuid = report.id ? report.id : report.uuid;

    report
      .update({
        stats_first_time_right_confirmed: value,
      })
      .then(() => {
        this.close();
        const dataTable = this.$refs.ftrDatatable as DataTable;
        if (dataTable) {
          dataTable.refresh();
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected close() {
    this.report = null;
    this.isEditingReport = false;
  }

  protected async fetchReport(report: Report) {
    this.report = await new Report()
      .refactor()
      .include('reject_reasons')
      .find(report.uuid);
  }

  protected async getDamages(report: Report) {
    this.damages = await new Damage()
      .filter({
        report: report.uuid,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isEditingReport = true;
  }

  // Getters
  protected get tableOptions() {
    return this.hasTcmgQuery ? this.tableOptionsTCMG : this.tableOptionsExpert;
  }

  protected get tableOptionsExpert(): Object {
    return {
      model: new Report().include('applicant').sort('downloaded_at', 'DESC')
        .filter(this.dataTableFilters),
      headers: this.$store.state.isServiceOrganization ? reportHeadersFromKey('ftr_logboek_bureau_as_img') : reportHeadersFromKey('ftr_logboek_bureau_as_bureau'),
      actions: [
        {
          type: 'edit',
          label: 'view',
          icon: 'remove_red_eye',
          tooltip: 'Bekijk afkeurredenen',
          action: async (datatable: DataTable, report: Report) => {
            this.report = report;
            await this.fetchReport(report);
            await this.getDamages(report);
          },
        },
      ],
      filter: [],
    };
  }

  protected get tableOptionsTCMG(): Object {
    return {
      model: new Report().sort('created', 'DESC').include(['reject_reasons', 'answers']).filter(this.dataTableFilters),
      headers: [
        {
          text: 'Verstuurd aan Aanvrager',
          value: 'downloaded_at',
          sortable: {
            key: 'downloaded_at',
            order: 'DESC',
          },
          transform: (downloaded_at: string) => {
            if (! downloaded_at || downloaded_at === '0000-00-00 00:00:00') {
              return '';
            }
            return formatDate(downloaded_at);
          },
        },
        {
          text: 'Zaaknummer',
          value: 'case_number',
          action: '/reports/{id}',
          sortable: {
            key: 'case_number',
            order: 'ASC',
          },
        },
        {
          text: 'Aangemaakt op',
          value: 'created_at',
          sortable: {
            key: 'created_at',
            order: 'DESC',
          },
          transform: (created: string) => {
            if (! created || created === this.databaseDate) {
              return '';
            }
            return formatDate(created);
          },
        },
        {
          text: 'Afkeurredenen',
          value: 'stats_first_time_right_tcmg_reasons',
          transform: (stats_first_time_right_tcmg_reasons: string[]) => {
            let names = '';
            stats_first_time_right_tcmg_reasons.forEach((key: string) => {
              names += `${getFirstTimeRightName(key, true)}, <br>`;
            });

            return names;
          },
        },
        {
          text: 'Toelichting',
          value: 'stats_first_time_right_tcmg_comment',
        },
        {
          text: 'Bedrijfsnaam',
          value: 'stats_first_time_right_tcmg_comment',
          transform: (stats_first_time_right_tcmg_reasons: string[], report: Report) => report.applicant?.name || '',
        },
        {
          text: 'Deskundige',
          value: 'expert',
          transform: (expert: User) => expert?.name,
        },
        {
          text: 'Controleur',
          value: 'precontroller',
          transform: (precontroller: User) => precontroller?.name,
        },
        {
          text: "TC'er",
          value: 'technisch_coordinator',
          transform: (technisch_coordinator: User) => technisch_coordinator?.name,
        },
      ],
      filter: [],
    };
  }

  protected get dataTableFilters() {
    this.tableFilters.ftr = this.hasTcmgQuery ? 'tcmg' : 'bureau';
    return this.tableFilters;
  }

  protected get hasTcmgQuery() {
    return this.$route.query.organization === 'img';
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: `Rapportage / ${this.hasTcmgQuery ? 'Logboek FTR TCMG' : 'Logboek FTR Bureau'}` },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged() {
    this.emitBreadcrumb();
    this.initialize();
  }
}

interface FtrLogboekFilters {
  organization?: string;
  downloaded_at?: any;
  ftr?: string;
  ftr_confirmed?: boolean;
  ftr_statuses?: string[];
  experts?: string[];
  kpiftr?: boolean;
  departments?: string[];
  is_test?: boolean;
  from?: string;
  to?: string;
}

interface FtrLogboekTableFilters {
  organization?: string;
  downloaded_at?: any;
  ftr?: string;
  ftr_confirmed?: boolean;
  ftr_statuses?: string[];
  experts?: string[];
  kpiftr?: boolean;
  departments?: string[];
  is_test?: boolean;
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v(
                "First Time Right Logboek " +
                  _vm._s(_vm.hasTcmgQuery ? "IMG" : "Bureau")
              )
            ])
          ]),
          _c(
            "v-flex",
            { staticClass: "relative", attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "div",
                { staticClass: "elementTitleButton absolute" },
                [
                  _c(
                    "MiButton",
                    {
                      attrs: {
                        disabled: _vm.isLoadingExport,
                        icon: "cloud_download",
                        round: "true",
                        outline: "true",
                        background: "true"
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.downloadExport()
                        }
                      }
                    },
                    [_vm._v(" Export to Excel ")]
                  )
                ],
                1
              )
            ]
          ),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "align-end": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", "mb-2": "" } },
                            [
                              _c("PeriodFilter", {
                                attrs: {
                                  to: _vm.filters.to,
                                  from: _vm.filters.from,
                                  clearable: false
                                },
                                on: { change: _vm.applyDates }
                              })
                            ],
                            1
                          ),
                          _vm.$store.state.isServiceOrganization &&
                          !_vm.hasTcmgQuery
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "with-border",
                                    attrs: {
                                      items: _vm.organizations,
                                      "item-text": "name",
                                      "item-value": "id",
                                      "hide-details": "",
                                      label: "Bureaus"
                                    },
                                    model: {
                                      value: _vm.filters.organization,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "organization",
                                          $$v
                                        )
                                      },
                                      expression: "filters.organization"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.$store.state.isServiceOrganization &&
                          !_vm.hasTcmgQuery
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("ReportDepartmentFilter", {
                                    attrs: { cssClass: "with-border" },
                                    model: {
                                      value: _vm.filters.departments,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "departments",
                                          $$v
                                        )
                                      },
                                      expression: "filters.departments"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  label: "Alleen contract kpi dossiers",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.kpiftr,
                                  callback: function($$v) {
                                    _vm.kpiftr = $$v
                                  },
                                  expression: "kpiftr"
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.$store.state.isServiceOrganization &&
                          !_vm.hasTcmgQuery
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "with-border",
                                      attrs: {
                                        items: _vm.ftrConfirmationStatusMap,
                                        "item-text": "label",
                                        "item-value": "key",
                                        "hide-details": "",
                                        placeholder: "Bevestiging status",
                                        clearable: "",
                                        multiple: ""
                                      },
                                      model: {
                                        value: _vm.filters.ftr_statuses,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            "ftr_statuses",
                                            $$v
                                          )
                                        },
                                        expression: "filters.ftr_statuses"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                  [
                                    _c("ReportExpertFilter", {
                                      attrs: {
                                        cssClass: "with-border",
                                        placeholder: "Opnemer",
                                        label: "Opnemer"
                                      },
                                      model: {
                                        value: _vm.filters.experts,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filters, "experts", $$v)
                                        },
                                        expression: "filters.experts"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right",
                              attrs: { sm12: "" }
                            },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "true",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.applyFilters()
                                    }
                                  }
                                },
                                [_vm._v(" Pas filters toe ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", {
                            staticClass: "separator",
                            attrs: { xs12: "" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("DataTable", {
                                ref: "ftrDatatable",
                                attrs: {
                                  options: _vm.tableOptions,
                                  visibility: _vm.visibility
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      ),
      _vm.isEditingReport
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "30vw", fullscreen: true },
              model: {
                value: _vm.isEditingReport,
                callback: function($$v) {
                  _vm.isEditingReport = $$v
                },
                expression: "isEditingReport"
              }
            },
            [
              _c(
                "DefaultDialog",
                { on: { close: _vm.close } },
                [
                  _c("template", { slot: "header" }, [_vm._v("Dossier FTR")]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "scroll-container",
                          attrs: { wrap: "", "justify-center": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs10: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--shadowed",
                                  class: {
                                    "reject-border":
                                      _vm.report.reject_reasons.length
                                  },
                                  attrs: { wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "profileContainer",
                                          attrs: { row: "", wrap: "" }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block profile__userName"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.report.case_number
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "profile__userRoles"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.report.type
                                                          ? _vm.report.type.name
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c("RejectReason", {
                                                attrs: { model: _vm.report }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("DamagePanel", {
                                attrs: {
                                  damages: _vm.damages,
                                  includes: [
                                    "validations",
                                    "reject_reasons",
                                    "media",
                                    "repairs"
                                  ],
                                  visibility: _vm.damageVisibility
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-end": "", "align-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fadedText link mr-3",
                                    on: { click: _vm.close }
                                  },
                                  [_vm._v("sluiten")]
                                ),
                                !_vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "MiButton",
                                      {
                                        staticClass: "mr-3",
                                        attrs: {
                                          color: "warning",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.updateFTR(
                                              _vm.report,
                                              "rejected"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Niet Akkoord ")]
                                    )
                                  : _vm._e(),
                                !_vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "MiButton",
                                      {
                                        staticClass: "mr-3",
                                        attrs: {
                                          color: "success",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.updateFTR(
                                              _vm.report,
                                              "confirmed"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Akkoord ")]
                                    )
                                  : _vm._e()
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }